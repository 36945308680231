export const setParams = () => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const paramsToAdd = [];

  if (!searchParams.has("matching_questionnaire")) {
    paramsToAdd.push("matching_questionnaire=true");
  }

  if (!searchParams.has("ending")) {
    const ending = "pricing";
    paramsToAdd.push(`ending=${ending}`);
  }

  if (!searchParams.has("order_id")) {
    const orderId = crypto.randomUUID();
    paramsToAdd.push(`order_id=${orderId}`);
  }

  if (paramsToAdd.length > 0) {
    const separator = searchParams.toString() ? "&" : "?";
    window.location.search += separator + paramsToAdd.join("&");
  }
};

const getCookieValue = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop()?.split(";").shift() : undefined;
};

const getRfsnLocalstorageParams = (): Record<string, string | null> => {
  const hiddenValues: Record<string, string | null> = {};
  Object.keys(localStorage).forEach((key) => {
    if (key.includes("rfsn")) {
      hiddenValues[key] = localStorage.getItem(key);
    }
  });
  return hiddenValues;
};

const getAnonymousId = async (): Promise<string> => {
  const timeout = 5000;
  const pollInterval = 150;

  const waitForAnalytics = (
    resolve: (id: string) => void,
    reject: () => void,
    elapsed = 0,
  ): void => {
    const analyticsUser = window.analytics?.user?.()?.anonymousId();

    if (analyticsUser) {
      resolve(analyticsUser);
    } else if (elapsed >= timeout) {
      resolve(crypto.randomUUID());
    } else {
      setTimeout(
        () => waitForAnalytics(resolve, reject, elapsed + pollInterval),
        pollInterval,
      );
    }
  };

  return new Promise((resolve) => {
    waitForAnalytics(resolve, () => resolve(crypto.randomUUID()));
  });
};

export const getHiddenFields = async (typeformId: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const uuid = urlParams.get("uuid");
  let aid = uuid;

  if (!uuid) {
    aid = await getAnonymousId();
  }

  const rfsnLocalstorageParams = getRfsnLocalstorageParams();

  const hiddenFields = {
    base_domain: "ourritual.com",
    aid,
    version: urlParams.get("version") || "xxx",
    coupon: urlParams.get("coupon") || "xxx",
    funnelid: urlParams.get("funnelid") || "xxx",
    funnel_start: urlParams.get("funnel_start") || "xxx",
    price: urlParams.get("price") || "xxx",
    first_name: urlParams.get("first_name") || "xxx",
    last_name: urlParams.get("last_name") || "xxx",
    partnerrefer: urlParams.get("partnerrefer") || "xxx",
    funnel_mode: urlParams.get("funnel_mode") || "xxx",
    uuid: aid,
    original_params: urlParams,
    ending: urlParams.get("ending"),
    form_id: typeformId,
    show_onboarding_app: urlParams.get("show_onboarding_app") || "false",
    affiliate: urlParams.get("affiliate") || "xxx",
    ttclid: urlParams.get("ttclid") || "",
    gclid: urlParams.get("gclid") || "",
    fbclid: urlParams.get("fbclid") || "",
    order_id: urlParams.get("order_id") || "",
    vwo_uuid: getCookieValue("_vwo_uuid") || "",
    utm_source: urlParams.get("utm_source") || "",
    utm_content: urlParams.get("utm_content") || "",
    utm_campaign: urlParams.get("utm_campaign") || "",
    utm_term: urlParams.get("utm_term") || "",
    utm_medium: urlParams.get("utm_medium") || "",
    matching: true,
    ...rfsnLocalstorageParams,
  };

  return hiddenFields;
};
