import { useEffect } from "react";
import RitualAnalytics from "client-analytics";
import { DEFAULT_MATCHING_SESSION_ID } from "../contstants/questionnaire";
import { useParams } from "react-router-dom";

export const useAnalytics = () => {
  const { id } = useParams();

  useEffect(() => {
    RitualAnalytics.initialize({
      trackClicks: false,
      trackViews: true,
      source: "matching_app",
    });
  }, []);

  useEffect(() => {
    const trackTypeformEvents = (e: MessageEvent) => {
      const queryParams = new URLSearchParams(window.location.search);
      const body = {
        gclid: queryParams.get("gclid"),
        funnelid: queryParams.get("funnelid"),
        funnel_start: queryParams.get("funnel_start"),
        ending: queryParams.get("ending"),
        fbc: "fb.1." + Date.now() + "." + queryParams.get("fbclid"),
      };

      if (e.data.type && e.data.type.indexOf("form") === 0) {
        const typeformEventType: string = e.data.type;

        const typeformMapping: { [key: string]: string } = {
          "form-submit": "typeform_submitted",
          "form-screen-changed": "typeform_question_passed",
        };

        const typeformEventBody = {
          fbc: body.fbc,
          typeformid:
            queryParams.get("typeformID") || id || DEFAULT_MATCHING_SESSION_ID,
          subid: queryParams.get("subid"),
          typeformquestionid: e.data.ref,
          gclid: queryParams.get("gclid"),
        };

        const event_name = typeformMapping[typeformEventType];
        if (event_name) {
          RitualAnalytics.track(event_name, typeformEventBody);
        }
      }
    };

    window.addEventListener("message", trackTypeformEvents);
    return () => {
      window.removeEventListener("message", trackTypeformEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
